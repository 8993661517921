import { FULL_LOGO } from "../../configs";
import classNames from "./navbar.module.scss";
import iconOne from "../../static/images/logos/iconOne.svg";
import iconTwo from "../../static/images/logos/iconTwo.svg";

function Navbar() {
  return (
    <nav className={classNames.navbar}>
      <div className={classNames.btnLeft}>
        <img src={iconOne} alt="" />
      </div>
      <img src={FULL_LOGO} alt="" className={classNames.logo} />
      <div className={classNames.btnRight}>
        <img src={iconTwo} alt="" />
      </div>
    </nav>
  );
}

export default Navbar;
