/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { useQuery } from "react-query";
import CryptoJS from "crypto-js";

const getUserDetails = async ({ queryKey }: any): Promise<any> => {
  const [_key, email] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.com/get_affiliate_data_no_logs",
    {
      email: email,
    }
  );
  return data[0];
};

export const useUserDetails = (email?: string) => {
  const query = useQuery(["getUserDetails", email], getUserDetails);
  return query;
};

const getRegisterdUsers = async () => {
  const { data } = await axios.get(
    "https://comms.globalxchange.com/listUsernames"
  );
  if (data.status) {
    let bytes = CryptoJS.Rabbit.decrypt(data.payload, "gmBuuQ6Er8XqYBd");
    let jsonString = bytes.toString(CryptoJS.enc.Utf8);
    let result_obj = JSON.parse(jsonString);
    return result_obj;
  }
};

export const useRegisterdUsers = () =>
  useQuery(["getRegisterdUsers"], getRegisterdUsers);

const getTicketDetail = async ({ queryKey }: any): Promise<any> => {
  const [_key, token] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.com/coin/investment/path/get?token=${token}`
  );
  return data?.paths[0];
};
export const useTicketDetail = (token?: string) =>
  useQuery(["getTicketDetail", token], getTicketDetail);
