import { Fragment } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import Routes from "./Routes";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });
  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
