import { useState } from "react";
import classNames from "./cartPage.module.scss";
import { FULL_LOGO, FULL_LOGO_TWO } from "../../configs";
import { useParams } from "react-router-dom";
import { useTicketDetail } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import closeRed from "../../static/images/icons/closeRed.svg";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import LoginCard from "../../components/LoginModal/LoginCard";
import { toast } from "react-toastify";

function CartPage(): JSX.Element {
  const { tokenname } = useParams();
  const { data, isLoading } = useTicketDetail(tokenname);
  const [count, setCount] = useState<number>(1);
  const [loginOpen, setLoginOpen] = useState(false);
  return (
    <div className={classNames.cartPage}>
      <div className={classNames.pageContent}>
        <Link to="/" className={classNames.topBar}>
          <img src={FULL_LOGO} alt="" className={classNames.navLogo} />
        </Link>
        <div className={classNames.header}>
          <div className={classNames.texts}>
            <div className={classNames.titleHead}>Ticket Cart</div>
            <div className={classNames.subTitleHead}>
              You Have One Ticket In Your Cart
            </div>
          </div>
          {isLoading ? (
            <div className={classNames.btnAsset}>
              <Skeleton height={25} width={25} circle />
              <Skeleton width={50} />
            </div>
          ) : (
            <div className={classNames.btnAsset}>
              <img src={data?.asset_metaData?.coinImage} alt="" />
              <span>{data?.asset}</span>
            </div>
          )}
        </div>
        <div className={classNames.cartList}>
          <div className={classNames.cartItmCard}>
            {isLoading ? (
              <div className={classNames.coinDetail}>
                <Skeleton
                  height={80}
                  width={80}
                  circle
                  style={{ marginRight: 20 }}
                />
                <div className={classNames.cardText}>
                  <Skeleton
                    width={200}
                    className={classNames.name}
                    style={{ marginBottom: 5 }}
                  />
                  <Skeleton width={200} className={classNames.symbool} />
                </div>
              </div>
            ) : (
              <div className={classNames.coinDetail}>
                <img src={data?.token_profile_data?.coinImage} alt="" />
                <div className={classNames.cardText}>
                  <div className={classNames.name}>
                    {data?.token_profile_data?.coinName}
                  </div>
                  <div className={classNames.symbool}>
                    ({data?.token_profile_data?.coinSymbol})
                  </div>
                </div>
              </div>
            )}
            <div className={classNames.count}>
              <div
                className={classNames.btnMinus}
                onClick={() => {
                  if (count > 1) setCount(count - 1);
                }}
              >
                -
              </div>
              <input
                type="number"
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                className={classNames.input}
              />
              <div
                className={classNames.btnPlus}
                onClick={() => {
                  setCount(count + 1);
                }}
              >
                +
              </div>
            </div>
            {isLoading ? (
              <Skeleton className={classNames.amount} width={200} />
            ) : (
              <div className={classNames.amount}>
                {FormatCurrency(count * data?.token_price, data?.asset)}{" "}
                {data?.asset}
              </div>
            )}
            <div className={classNames.closeCircle}>
              <img src={closeRed} alt="" />
            </div>
          </div>
        </div>
      </div>
      <aside className={classNames.sideBar}>
        <img src={FULL_LOGO_TWO} alt="" className={classNames.logo} />
        {loginOpen ? (
          <>
            <LoginCard
              onSuccess={() => {
                toast.success("Logged In");
              }}
              bare={{
                className: classNames.bare,
                classNameBtn: classNames.btnLogin,
                text: "Login",
              }}
            />
          </>
        ) : (
          <>
            <div
              className={classNames.btnLogin}
              onClick={() => setLoginOpen(true)}
            >
              Login
            </div>
            <div className={classNames.btnRegister}>Register</div>
          </>
        )}
      </aside>
    </div>
  );
}

export default CartPage;
