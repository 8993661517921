import { BrowserRouter, Routes, Route } from "react-router-dom";
import CartPage from "./pages/CartPage";
import LandingPage from "./pages/LandingPage";

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/cart">
          <Route path=":tokenname" element={<CartPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
