import Navbar from "../../components/Navbar";
import classNames from "./landingPage.module.scss";
import rightAngleRedBlue from "../../static/images/icons/rightAngleRedBlue.svg";
import bgHome from "../../static/images/bg/bgHome.webp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function LandingPage(): JSX.Element {
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  return (
    <div className={classNames.landingPage}>
      <Navbar />
      <div className={classNames.pageWrap}>
        <div className={classNames.textSide}>
          <div className={classNames.subtitle}>Buy &amp; Sell Ticket NFT’s</div>
          <div className={classNames.title}>Instant. Easy. Social</div>
          <label className={classNames.searchWrap}>
            <input
              type="text"
              className={classNames.input}
              placeholder="Enter Ticket Code"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <div
              className={classNames.btnGo}
              onClick={() => {
                if (token) navigate(`/cart/${token}`);
                else toast("Enter Token");
              }}
            >
              <img src={rightAngleRedBlue} alt="" className={classNames.icon} />
            </div>
          </label>
        </div>
        <img src={bgHome} alt="" className={classNames.bgHome} />
      </div>
    </div>
  );
}

export default LandingPage;
